import React, { FC } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LoginForm from "../Auth/LoginForm";
import { RouterProps } from "../../shared/types/route.type";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import AppRoutes from "../../routes";
import Customers from "./Customers";
import CustomerProfile from "./Customers/CustomerProfile";
import Configuration from "./Configuration";
import Refer from "./Refer";
import Orders from "./Orders";
import Report from "./Report";
import WithdrawReqs from "./WithdrawReqs";
import AccountManager from "./AccountManager";
// import Holdings from "./Holdings";
// import Home from "./Home";

const appWrapperRouter = () => {
  const routes: RouterProps[] = [
    { exact: true, path: appRoutes.CUSTOMERS, component: Customers },
    {
      exact: true,
      path: appRoutes.CUSTOMERS_PROFILE,
      component: CustomerProfile,
    },
    { exact: true, path: appRoutes.ORDERS, component: Orders },
    { exact: true, path: appRoutes.CONFIG, component: Configuration },
    { exact: true, path: appRoutes.REFER, component: Refer },
    { exact: true, path: appRoutes.REPORT, component: Report },
    { exact: true, path: appRoutes.WITHDRAW, component: WithdrawReqs },
    { exact: true, path: appRoutes.ACCOUNT_MANAGER, component: AccountManager },
    // { exact: true, path: appRoutes.WALLET, component: Wallet },
    // { exact: true, path: appRoutes.FAQ, component: Faq },
  ];

  return (
    <Switch>
      {routes.map(({ component, ...routerProps }) => (
        <Route
          key={routerProps.path}
          {...routerProps}
          component={component as FC}
        />
      ))}
      <Route
        path="*"
        render={() => (
          <Redirect
            exact
            strict
            from={appRoutes.APP}
            to={appRoutes.CUSTOMERS}
          />
        )}
      />
    </Switch>
  );
};

export default appWrapperRouter;
