/* Auth Routes */
export const AUTH = "/auth";
export const REGISTER = AUTH + "/register";
export const LOGIN = AUTH + "/login";
export const RESET_PASSWORD = AUTH + "/reset-password";

/* App Route */
export const APP = "/app";
export const CUSTOMERS = APP + "/customers";
export const ORDERS = APP + "/orders";
export const CUSTOMERS_PROFILE = APP + "/customers/:customerId";
export const HOME = APP + "/home";
export const HOLDINGS = APP + "/holdings";
export const WALLET = APP + "/wallet";
export const REPORT = APP + "/report";
export const WITHDRAW = APP + "/withdrawals";
export const PROFILE = APP + "/profile";
export const REFER = APP + "/refer";
export const CONFIG = APP + "/configurations";
export const REACH_US = APP + "/reach-us";
export const ACCOUNT_MANAGER = APP + "/account-manager";
